



















































import { Component, Vue } from "vue-property-decorator";
import { AuthService } from "@/models/auth/AuthService";
import { container } from "tsyringe";
@Component({
    components: {
    }
})
export default class LoginPage extends Vue {
    // region private fields
    private loginId = "";
    private password = "";
    private isLoading = false;
    private isLoginError = false;
    private emailRules = [
        (v: string) => !!v || "メールアドレスを入力してください",
        (v: string) => /.+@.+\..+/.test(v) || "無効なメールアドレスです"
    ];
    // #endregion
    // #region properties
    /**
     * 認証及びアカウント情報サービス.
     */
    private readonly authService: AuthService = container.resolve(AuthService);
    // #endregion
    private async login(): Promise<void> {
        this.isLoginError = false;
        this.isLoading = true;
        const isSuccess = await this.authService.login(this.loginId, this.password);
        this.isLoading = false;
        if (isSuccess) {
            location.href = "/";
            location.reload();
            return;
        }
        this.isLoginError = true;
    }
}
