












































import { Component, Vue } from "vue-property-decorator";
import ManagementsMain from "@/components/Managements/ManagementsMain.vue";
import LoginPage from "@/components/Managements/Login/LoginPage.vue";
import { container } from "tsyringe";
import { AuthService } from "@/models/auth/AuthService";

/**
 * 認証画面および認証済みの表示する画面を提供します．
 */
@Component({
    components: {
        LoginPage
    }
})
export default class AutheticatedFrame extends Vue {
    private isAutheticated = false;
    private isLoading = false;
    private isLoginChecked = false;

    // #region private properties
    /**
     * 認証ストア
     */
    private readonly authService: AuthService = container.resolve(AuthService);
    // #endregion

    // #region private methods
    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private async created(): Promise<void> {
        try {
            this.isLoading = true;
            this.isAutheticated = await this.checkLoginState();
            this.isLoginChecked = true;
            if (!this.isAutheticated) {
                // ストレージデータのクリア
                this.authService.logout();
                return;
            }
        }
        catch (ex) {
            logger.error(ex);
            console.error(ex);
        }
        finally {
            this.isLoading = false;
        }
    }

    /**
     * ログアウトします.
     */
    private logout(): void {
        this.authService.logout();
        this.$router.push("/");
        location.reload();
    }

    /**
     * ログイン状態が有効かどうかチェックします.
     * @returns ログイン状態が有効かどうか
     */
    private async checkLoginState(): Promise<boolean> {
        // トークンを検証して有効ならそのまま終了
        if (await this.authService.validateToken()) {
            return true;
        }

        // トークンが無効ならリフレッシュトークンを試みる
        if (await this.authService.tryRefreshToken()) {
            // リフレッシュに成功したらもう一度トークンの検証をする
            return await this.authService.validateToken();
        }
        return false;
    }
    // #endregion
}
